import { Button, Card, Image, Input, Menu, Spin } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import { MdMiscellaneousServices } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { ICategory, IService } from "../../lib/model";
import { useCTX } from "../../state";

export default function ServiceList() {
  useTitle("Service");

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [services, setServices] = useState<IService[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtered, setFiltered] = useState<IService[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const [infiniteService, setInfiniteService] = useState({
    page: 0,
    remaining: 0,
  });

  const {
    state: { settings, isocode },
  } = useCTX();
  const navigate = useNavigate();

  const [servicesRequest, servicesLoading] = useRequest();
  const [categoriesRequest, categoriesLoading] = useRequest();

  const getServices = useCallback(
    (category: string, page: number, changed = false) => {
      servicesRequest(
        "post",
        "services/api/servicesSearch/",
        (res) => {
          if (changed) {
            setServices(res.data.services_list);
          } else {
            setServices((old) => [...old, ...res.data.services_list]);
          }
          setInfiniteService({
            page: res.data.current_page,
            remaining: res.data.additional_pages,
          });
        },
        {
          ...(category !== "All" ? { category } : {}),
          pageRequested: page,
        }
      );
    },
    [servicesRequest]
  );

  useEffect(() => {
    categoriesRequest(
      "post",
      "categories/api/categoriesList/",
      (res) => {
        setCategories(res.data.categ_list);
      },
      {
        pageRequested: 1,
      }
    );
  }, [categoriesRequest]);

  useEffect(() => {
    const query = searchQuery.trim().toLowerCase();
    if (query.length > 0) {
      setFiltered(
        services.filter(
          (s) =>
            s.service_name.toLowerCase().includes(query) ||
            s.service_long_descr.toLowerCase().includes(query)
        )
      );
    } else {
      setFiltered(services);
    }
  }, [searchQuery, services]);

  useEffect(() => {
    getServices(selectedCategory, 1, true);
  }, [selectedCategory, getServices]);

  return (
    <div className="w-full">
      <Input
        autoFocus
        size="large"
        placeholder="Search Service..."
        prefix={<AiOutlineSearch />}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="flex flex-col md:flex-row my-4">
        <div className="w-full md:w-1/5">
          {categoriesLoading ? (
            <div className="flex justify-center">
              <Spin size="small" />
            </div>
          ) : (
            <Menu
              mode="inline"
              className="text-xs bg-gray-100 my-2 w-full"
              selectedKeys={[selectedCategory]}
              items={[
                {
                  key: "All",
                  label: "All",
                  icon: <MdMiscellaneousServices />,
                },
                ...categories.map((c) => ({
                  key: c.categ_name,
                  label: c.categ_name,
                  icon: <MdMiscellaneousServices />,
                })),
              ]}
              onClick={(e) => setSelectedCategory(e.key)}
            />
          )}
        </div>
        <div className="w-full md:w-4/5">
          <Fragment>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {filtered.map((s) => (
                <Card
                  key={s.service_id}
                  hoverable
                  cover={
                    <Image
                      alt={s.service_name}
                      src={s.service_image || "/images/covers/empty.png"}
                      preview={false}
                      height={150}
                      className="object-cover"
                    />
                  }
                  className="m-2"
                  onClick={() => navigate(`/service/${s.service_id}`)}
                >
                  <Card.Meta
                    title={s.service_name}
                    description={s.service_long_descr}
                    style={{ marginBottom: 8 }}
                  />
                  <span
                    style={{ backgroundColor: settings.color }}
                    className="text-white px-2 py-1 rounded-lg font-bold text-xs"
                  >
                    {isocode?.currency_symbol}.{" "}
                    {s.service_price.toLocaleString()}
                  </span>
                </Card>
              ))}
            </div>
            {infiniteService.remaining === 0 && servicesLoading && (
              <div className="w-full my-4 flex justify-center items-center">
                <Spin />
              </div>
            )}
            {infiniteService.remaining > 0 &&
              filtered.length === services.length && (
                <div className="my-4 flex justify-center">
                  <Button
                    icon={<IoReload />}
                    loading={servicesLoading}
                    onClick={() => {
                      getServices(selectedCategory, infiniteService.page + 1);
                      setInfiniteService({
                        ...infiniteService,
                        page: infiniteService.page + 1,
                      });
                    }}
                  >
                    more
                  </Button>
                </div>
              )}
          </Fragment>
        </div>
      </div>
    </div>
  );
}
