import {
  Badge,
  Button,
  Descriptions,
  List,
  Radio,
  Spin,
  Tag,
  Typography,
} from "antd";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoGitNetwork } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { db } from "../../lib/firebase";
import { IWorkflow, WorkflowState } from "../../lib/model";
import { getWorkflowTitle } from "../../lib/utils";
import { useCTX } from "../../state";

export default function Workflow() {
  useTitle("Workflow");

  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);

  const [filterType, setFilterType] = useState<WorkflowState | "ALL">("ALL");
  const [filtered, setFiltered] = useState<IWorkflow[]>([]);

  const {
    state: { user },
  } = useCTX();
  const navigate = useNavigate();
  const [request, loading] = useRequest(true);

  useEffect(() => {
    request(
      "post",
      "workflows/api/weblist/",
      async (res) => {
        const flows = [];
        for (const w of res.data.workflows) {
          if (w.evaluated) {
            flows.push({ ...w, state: WorkflowState.COMPLETED, pending: 0 });
          } else {
            const cref = collection(
              db,
              "workflows",
              `workflow_${w.id}`,
              "messages"
            );

            let q = query(
              cref,
              where("receiverMail", "==", user?.email),
              where("read", "==", false),
              orderBy("timestamp", "asc")
            );

            const snapshot = await getDocs(q);

            flows.push({ ...w, pending: snapshot.docs.length });
          }
        }
        setWorkflows(flows);
      },
      {
        token: localStorage.getItem("token"),
        type: "W",
      }
    );
  }, [request, user?.email]);

  useEffect(() => {
    if (filterType === "ALL") {
      setFiltered(workflows);
    } else {
      setFiltered(workflows.filter((w) => w.state === filterType));
    }
  }, [filterType, workflows]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoGitNetwork className="mr-2" /> Workflows
        </Typography.Title>
        <Radio.Group
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          size="small"
          buttonStyle="solid"
          className="my-4 flex justify-end"
        >
          <Radio.Button value="ALL">ALL</Radio.Button>
          {user?.agent_is_manager ? (
            <Radio.Button value={WorkflowState.UNASSIGNED}>
              {WorkflowState.UNASSIGNED}
            </Radio.Button>
          ) : (
            <Radio.Button value={WorkflowState.ASSIGNED}>
              {WorkflowState.ASSIGNED}
            </Radio.Button>
          )}
          <Radio.Button value={WorkflowState.COMPLETED}>
            {WorkflowState.COMPLETED}
          </Radio.Button>
          <Radio.Button value={WorkflowState.ESCALATED}>
            {WorkflowState.ESCALATED}
          </Radio.Button>
        </Radio.Group>
        <List
          className="px-8"
          itemLayout="vertical"
          dataSource={filtered}
          renderItem={(item) => (
            <List.Item
              extra={
                <div className="flex items-start">
                  <Tag
                    color={
                      item.state === WorkflowState.ASSIGNED
                        ? "yellow"
                        : item.state === WorkflowState.COMPLETED
                        ? "green"
                        : "red"
                    }
                  >
                    {item.state}
                  </Tag>
                  <Badge count={item.pending}>
                    <Button
                      icon={
                        [
                          WorkflowState.COMPLETED,
                          WorkflowState.ESCALATED,
                        ].includes(item.state) ? (
                          <FaEye />
                        ) : (
                          <MdEdit />
                        )
                      }
                      className="ml-8"
                      onClick={() => navigate(`/workflow/${item.id}`)}
                    />
                  </Badge>
                </div>
              }
            >
              <List.Item.Meta
                avatar={<IoGitNetwork />}
                title={getWorkflowTitle(item.type)}
                description={
                  <Descriptions>
                    <Descriptions.Item label="workflow">
                      {item.id}
                    </Descriptions.Item>
                    <Descriptions.Item label="agent">
                      {item.agent ? item.agent.email : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="object">
                      {item.object_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="approved">
                      <Tag color={item.approved ? "green" : "red"}>
                        {item.approved ? <TiTick /> : <RxCross2 />}
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="created">
                      <ReactTimeago date={item.created_at} />
                    </Descriptions.Item>
                    <Descriptions.Item label="last updated">
                      <ReactTimeago date={item.updated_at} />
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </PrivateRoute>
  );
}
