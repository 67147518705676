import {
  Avatar,
  Button,
  Descriptions,
  Modal,
  Spin,
  Tag,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
import { Fragment, useEffect, useState } from "react";
import { IoGitNetwork } from "react-icons/io5";
import {
  MdDataObject,
  MdOutlineAssignmentInd,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import ReactTimeago from "react-timeago";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { IWorkflow, WorkflowState, WorkflowType } from "../../lib/model";
import { getWorkflowTitle } from "../../lib/utils";
import { useCTX } from "../../state";
import AssignWorkflow from "./Assign";
import WorkflowChat from "./WorkflowChat";

export default function WorkflowDetail() {
  useTitle("Workflow Detail");

  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);

  const [comment, setComment] = useState("");
  const [approved, setApproved] = useState(false);
  const [approveFlow, setApproveFlow] = useState(false);

  const [showAssign, setShowAssign] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { user },
  } = useCTX();
  const [approveRequest, approving] = useRequest();
  const [detailRequest, loading] = useRequest(true);

  const approveWorkflow = () => {
    approveRequest(
      "post",
      "workflows/api/webapprove/",
      () => {
        setWorkflow((old) => ({
          ...old!,
          state: WorkflowState.COMPLETED,
          approved,
          evaluated: true,
        }));
        setApproveFlow(false);
      },
      {
        token: localStorage.getItem("token"),
        flow_id: params.id,
        is_agent: true,
        approved,
        comment,
      }
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "workflows/api/webdetail/",
      (res) => {
        setWorkflow(res.data.workflow);
        setApproveFlow(false);
      },
      {
        token: localStorage.getItem("token"),
        workflow_id: params.id,
      }
    );
  }, [params.id, detailRequest]);

  useEffect(() => {
    if (!loading && !workflow) {
      navigate(-1);
    }
  }, [loading, workflow, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!workflow) {
    return null;
  }

  return (
    <PrivateRoute>
      <div>
        {showAssign && (
          <AssignWorkflow
            setShow={setShowAssign}
            workflow={workflow}
            setWorkflow={setWorkflow}
          />
        )}
      </div>
      <Modal
        open={approveFlow}
        title={`Workflow (${workflow.id})`}
        onCancel={() => setApproveFlow(false)}
        confirmLoading={approving}
        okText="Submit"
        okButtonProps={{
          disabled: comment.trim().length === 0,
        }}
        onOk={approveWorkflow}
      >
        <div className="my-4">
          <span>
            You have{" "}
            {approved ? (
              <span className="text-green-500 underline">accepted</span>
            ) : (
              <span className="text-red-500 underline">rejected</span>
            )}{" "}
            the workflow. Are you sure you want to submit?
          </span>
          <TextArea
            rows={5}
            value={comment}
            className="mt-4"
            placeholder="Write a comment"
            onChange={(e) => setComment(e.target.value)}
          />
          <span style={{ fontSize: "0.7rem" }}>
            <span className="text-red-500">*</span> please add a comment.
          </span>
        </div>
      </Modal>
      <div className="w-full font-poppins">
        <div className="flex items-center justify-between">
          <BackButton />
          {!workflow.evaluated && (
            <div className="my-8">
              {user?.agent_is_manager &&
                [WorkflowState.ASSIGNED, WorkflowState.ESCALATED].includes(
                  workflow.state
                ) && (
                  <Button
                    icon={<MdOutlineAssignmentInd />}
                    className="mr-2"
                    onClick={() => setShowAssign(true)}
                  >
                    Assign
                  </Button>
                )}
              {(user?.agent_is_manager ||
                workflow.state === WorkflowState.ASSIGNED) && (
                <Fragment>
                  <Button
                    type="primary"
                    icon={<TiTick />}
                    className="mr-2"
                    onClick={() => {
                      setApproved(true);
                      setApproveFlow(true);
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    danger
                    type="primary"
                    icon={<RxCross2 />}
                    onClick={() => {
                      setApproved(false);
                      setApproveFlow(true);
                    }}
                  >
                    Reject
                  </Button>
                </Fragment>
              )}
            </div>
          )}
        </div>
        <div className="mx-8">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center pb-2"
          >
            <IoGitNetwork className="mr-2 text-xs" /> Workflow ({workflow.id})
          </Typography.Title>
          <Descriptions>
            <Descriptions.Item label="type">
              {getWorkflowTitle(workflow.type)}
            </Descriptions.Item>
            <Descriptions.Item label="object id">
              {workflow.object_id}
            </Descriptions.Item>
            <Descriptions.Item label="state">
              <Tag
                color={
                  workflow.evaluated
                    ? "green"
                    : workflow.state === WorkflowState.ASSIGNED
                    ? "yellow"
                    : "red"
                }
              >
                {workflow.evaluated ? WorkflowState.COMPLETED : workflow.state}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="approved">
              <Tag color={workflow.approved ? "green" : "red"}>
                {workflow.approved ? <TiTick /> : <RxCross2 />}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="additional approval">
              <Tag color="blue">
                {workflow.additional_approval ? "required" : "not required"}
              </Tag>
            </Descriptions.Item>
            {workflow.additional_approval && (
              <>
                <Descriptions.Item label="additional approved">
                  <Tag color={workflow.additional_approved ? "green" : "red"}>
                    {workflow.additional_approved ? <TiTick /> : <RxCross2 />}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="additional approver">
                  <div className="max-w-max rounded-lg text-xs border border-gray-200 border-solid px-2 py-1 flex items-center font-montserrat">
                    <div className="text-black flex items-center">
                      <Avatar
                        src={
                          workflow.additional_approver.photo ||
                          "/images/no-avatar.jpeg"
                        }
                        size={"small"}
                        className="mr-2"
                      />
                      <div className="flex items-center mr-8">
                        {workflow.additional_approver.email}
                      </div>
                    </div>
                  </div>
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="created">
              <ReactTimeago date={workflow.created_at} />
            </Descriptions.Item>
            <Descriptions.Item label="last updated">
              <ReactTimeago date={workflow.updated_at} />
            </Descriptions.Item>
          </Descriptions>
          <div className="my-8">
            <Typography.Title
              level={5}
              className="font-poppins flex items-center pb-2"
            >
              <MdDataObject className="mr-2" /> Object
            </Typography.Title>
            <Descriptions layout="vertical">
              {Object.keys(workflow.object).map((k) => (
                <Descriptions.Item
                  key={k}
                  label={k}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {Array.isArray(workflow.object[k]) ? (
                    <ul className="m-0 p-0 pl-4">
                      {workflow.object[k].map((i: any) => (
                        <li key={i}>{i}</li>
                      ))}
                    </ul>
                  ) : `${workflow.object[k]}`.startsWith("http://") ||
                    `${workflow.object[k]}`.startsWith("https://") ? (
                    <Link href={workflow.object[k]} target="_blank">
                      {workflow.object[k]}
                    </Link>
                  ) : (
                    workflow.object[k]
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
            <div></div>
          </div>
          {workflow.state === WorkflowState.COMPLETED && (
            <div className="my-8">
              <Typography.Title
                level={5}
                className="font-poppins flex items-center pb-2"
              >
                Final Comment
              </Typography.Title>
              <span className="text-xs">{workflow.comment}</span>
            </div>
          )}
          <div className="flex items-center">
            <div className="w-full">
              {workflow.type !== WorkflowType.PB && (
                <WorkflowChat workflow={workflow} />
              )}
            </div>
            {workflow.agent && (
              <div className="flex flex-col items-center">
                <MdOutlineSupportAgent className="text-4xl" />
                <span>{workflow.agent.email}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
}
