import { Button, Descriptions, List, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import Link from "antd/es/typography/Link";
import { Fragment, useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useRequest } from "../../hooks/useRequest";
import {
  IAuthorizationEvent,
  MPriceType,
  MVariableDistanceUnit,
  MVariableTimeUnit,
  MVariableUnit,
} from "../../lib/model";
import { useCTX } from "../../state";

export default function Event() {
  const [events, setEvents] = useState<IAuthorizationEvent[]>([]);

  const [event, setEvent] = useState<IAuthorizationEvent | null>(null);
  const [approveEvent, setApproveEvent] = useState(false);
  const [approving, setApproving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [comment, setComment] = useState("");

  const {
    state: { user, isocode },
  } = useCTX();
  const [updateRequest] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onApprove = () => {
    setApproving(true);
    updateRequest(
      "post",
      "events/api/updateEvent/",
      () => {
        setEvents((old) => old.filter((e) => e.id !== event?.id));
        setApproving(false);
      },
      {
        email: user?.email,
        eventId: event?.id,
        status: approved ? "A" : "R",
        comment,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "events/api/listEvents/",
      (res) => {
        const e = res.data.events;
        setEvents([...e.appointments, ...e.team_members, ...e.offerings]);
      },
      {
        email: user?.email,
        pageRequested: 1,
      }
    );
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <Fragment>
      <Modal
        open={approveEvent}
        title={
          event?.event_type === "A"
            ? "Appointment"
            : event?.event_type === "T"
            ? "Team Membership"
            : "Offering Acceptance"
        }
        onCancel={() => setApproveEvent(false)}
        confirmLoading={approving}
        okText="Submit"
        okButtonProps={{
          disabled: comment.trim().length === 0,
        }}
        onOk={onApprove}
      >
        <div className="my-4">
          <span>
            You have{" "}
            {approved ? (
              <span className="text-green-500 underline">accepted</span>
            ) : (
              <span className="text-red-500 underline">rejected</span>
            )}{" "}
            the event. Are you sure you want to submit?
          </span>
          <TextArea
            rows={5}
            value={comment}
            className="mt-4"
            placeholder="Write a comment"
            onChange={(e) => setComment(e.target.value)}
          />
          <span style={{ fontSize: "0.7rem" }}>
            <span className="text-red-500">*</span> please add a comment.
          </span>
        </div>
      </Modal>
      <List
        className="px-8"
        itemLayout="vertical"
        dataSource={events}
        renderItem={(item) => (
          <List.Item
            extra={
              <div className="flex items-start">
                <Button
                  type="primary"
                  icon={<TiTick />}
                  className="mr-2"
                  onClick={() => {
                    setEvent(item);
                    setApproved(true);
                    setApproveEvent(true);
                  }}
                >
                  Accept
                </Button>
                <Button
                  danger
                  type="primary"
                  icon={<RxCross2 />}
                  onClick={() => {
                    setEvent(item);
                    setApproved(false);
                    setApproveEvent(true);
                  }}
                >
                  Reject
                </Button>
              </div>
            }
          >
            <List.Item.Meta
              avatar={<CiLock />}
              title={
                item.event_type === "A"
                  ? "Appointment"
                  : item.event_type === "T"
                  ? "Team Membership"
                  : "Offering Acceptance"
              }
              description={
                <Descriptions>
                  {Object.keys(
                    item.event_type === "A"
                      ? item.appointment
                      : item.event_type === "T"
                      ? item.entity_link
                      : item.offering
                  ).map((k) => {
                    const o = (
                      item.event_type === "A"
                        ? item.appointment
                        : item.event_type === "T"
                        ? item.entity_link
                        : item.offering
                    ) as any;

                    return (
                      <Descriptions.Item
                        key={k}
                        label={k}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {`${o[k]}`.startsWith("http://") ||
                        `${o[k]}`.startsWith("https://") ? (
                          <Link href={o[k]} target="_blank">
                            {o[k]}
                          </Link>
                        ) : k === "price" ? (
                          `${isocode?.currency_symbol}. ${o[k]}`
                        ) : k === "price_type" ? (
                          MPriceType.getKey(o[k])
                        ) : k === "variable_unit" ? (
                          MVariableUnit.getKey(o[k])
                        ) : k === "var_time_unit" ? (
                          MVariableTimeUnit.getKey(o[k])
                        ) : k === "var_distance_unit" ? (
                          MVariableDistanceUnit.getKey(o[k])
                        ) : (
                          o[k]
                        )}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              }
            />
          </List.Item>
        )}
      />
    </Fragment>
  );
}
