import { Badge, Button, Descriptions, List, Modal, Spin, Tag } from "antd";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoGitNetwork } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import ReactTimeago from "react-timeago";
import { useRequest } from "../../hooks/useRequest";
import { db } from "../../lib/firebase";
import { IWorkflow, WorkflowState } from "../../lib/model";
import { getWorkflowTitle } from "../../lib/utils";
import { useCTX } from "../../state";
import WorkflowChat from "./WorkflowChat";

export default function Workflow() {
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);

  const [request, loading] = useRequest(true);
  const {
    state: { user },
  } = useCTX();

  useEffect(() => {
    request(
      "post",
      "workflows/api/weblist/",
      async (res) => {
        const flows = [];
        for (const w of res.data.workflows) {
          if (!w.evaluated) {
            const cref = collection(
              db,
              "workflows",
              `workflow_${w.id}`,
              "messages"
            );

            let q = query(
              cref,
              where("receiverMail", "==", user?.email),
              where("read", "==", false),
              orderBy("timestamp", "asc")
            );

            const snapshot = await getDocs(q);

            flows.push({ ...w, pending: snapshot.docs.length });
          } else {
            flows.push({ ...w, pending: 0 });
          }
        }
        setWorkflows(flows);
      },
      {
        token: localStorage.getItem("token"),
        type: "A",
      }
    );
  }, [request, user?.email]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Modal
        open={!!workflow}
        title={workflow ? getWorkflowTitle(workflow.state) : ""}
        footer={null}
        bodyStyle={{ height: 450 }}
        onCancel={() => setWorkflow(null)}
      >
        {workflow && <WorkflowChat workflow={workflow} />}
      </Modal>
      <List
        className="px-8"
        itemLayout="vertical"
        dataSource={workflows}
        renderItem={(item) => (
          <List.Item
            extra={
              <Badge count={item.pending}>
                <Button
                  icon={<FaEye />}
                  className="ml-8"
                  onClick={() => setWorkflow(item)}
                />
              </Badge>
            }
          >
            <List.Item.Meta
              avatar={<IoGitNetwork />}
              title={getWorkflowTitle(item.type)}
              description={
                <Descriptions>
                  <Descriptions.Item label="status">
                    <Tag
                      color={
                        item.state === WorkflowState.COMPLETED
                          ? "blue"
                          : "yellow"
                      }
                    >
                      {item.state === WorkflowState.COMPLETED
                        ? "completed"
                        : "pending"}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="approved">
                    <Tag color={item.approved ? "green" : "red"}>
                      {item.approved ? <TiTick /> : <RxCross2 />}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="created">
                    <ReactTimeago date={item.created_at} />
                  </Descriptions.Item>
                  <Descriptions.Item label="last updated">
                    <ReactTimeago date={item.updated_at} />
                  </Descriptions.Item>
                </Descriptions>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}
